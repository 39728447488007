body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul.no-bullet {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.App {
  background-color: #282c34;
}

.center {
  display: table;
  margin: 0 auto;
}

.post-width {
  width: 100%;
  background-color: #282c34;
}

.center-text {
  text-align: center;
  color: aqua;
  font-size: 200%;
  margin: 1%;
}

.create-bg {
  background-color: #282c34;
}