.section-padding {
    padding-top: 1%;
}

.comment {
    background: transparent !important;
}

.custom-button {
    text-align: left;
    color: black !important;
}

.btn-link {
    color: black !important;
}

.submit-button-padding {
    padding-bottom: 1%;
}

.text-size {
    font-size: 150%;
}

.btn-custom {
    margin-top: 1%;
    color: #282c34;
    background-color: aqua !important;
}

.btn-custom-no-pad {
    color: #282c34;
    background-color: aqua !important;
}

.no-border {
    border: 0 !important;
}